import App, { Container } from 'next/app'
import React from 'react'
import withRedux from '../hocs/withRedux'
import { Provider } from 'react-redux'
import Head from 'next/head'
import '../styles/index.css'
import 'nprogress/nprogress.css'
import 'normalize.css/normalize.css'
import 'react-toastify/dist/ReactToastify.css';
import * as gtag from '../lib/gtag'
import NProgress from 'nprogress'
import Router from 'next/router'
import * as Sentry from '@sentry/node'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
});

NProgress.configure({ showSpinner: false, easing: 'linear', speed: 300 });
Router.onRouteChangeStart = (url) => {
  console.log(`Loading: ${url}`)
  NProgress.start()
}
Router.onRouteChangeComplete = (url) => {
  NProgress.done()
  gtag.pageview(url)
}
Router.onRouteChangeError = () => NProgress.done()
console.log("hola")

class MyApp extends App {
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      console.log("getting initial props _app")
      pageProps = await Component.getInitialProps(ctx) || {}
    }
    const url = {
      pathname: ctx.pathname,
      query: ctx.query,
    }
    pageProps.url = url
    return { pageProps }
  }

  componentDidMount() {
    console.log("mounted master")
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && 'ontouchstart' in document)
      document.body.className = document.body.className.replace(/\bno-touch\b/, "")
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
    console.log('CUSTOM ERROR HANDLING', error)
    // This is needed to render errors correctly in development / production
    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, reduxStore, err } = this.props
    return <Container>
      <Provider store={reduxStore}>
        <Component {...pageProps} err={err} />
      </Provider>
    </Container>
  }
}

export default withRedux(MyApp)